import { useMemo } from "react";
import formatDate from "../../helpers/formatDate";
import { DemandResponseEventInfoDTO } from "../apis/EnergyNetV4ApiTypes"
import GridBox from "./GridBox";

const AdminDemandResponseEvent = ({ event, onClickHandler }: { event: DemandResponseEventInfoDTO, onClickHandler?: (id: string) => void }) => {
  const startDate = useMemo(() => new Date(event.startTime), [event.startTime]);
  const endDate = useMemo(() => new Date(event.endTime), [event.endTime]);
  return (
    <GridBox id={event.eventId} onClickHandler={onClickHandler} gridItems={[
      { heading: "Time and Duration", content: event.timeAndDuration, colSpan: 4 },
      { heading: "Start", content: `${formatDate(startDate)} ${startDate.toLocaleTimeString()}`, colSpan: 3 },
      { heading: "End", content: `${formatDate(endDate)} ${endDate.toLocaleTimeString()}`, colSpan: 3 },
      { heading: "Behavioral", content: event.isBehavioralOnly ? "Yes" : "No", colSpan: 2 },
      { heading: "Affected Groups", content: event.affectedGroups.length > 0 ? event.affectedGroups.map(g => g.name).join(',') : "All Vehicles", colSpan: 2 },
      { heading: "Notifications", content: `${event.demandResponseEventNotifications.some(n => n.notifySms) ? "SMS " : ""} ${event.demandResponseEventNotifications.some(n => n.notifyEmail) ? "Email " : ""} ${event.disableNotifications ? "(All Disabled)" : ""}`, colSpan: 4 },
      { heading: "Details", content: <p className="whitespace-normal text-sm">{event.details.replace("hevay", "heavy")}</p>, colSpan: 6 },
    ]} />
  );
};

export default AdminDemandResponseEvent;

import React from 'react';
import { GridContent } from './GridContent';

interface GridItem {
  heading: string | JSX.Element;
  content: string | JSX.Element;
  colSpan: number;
}

interface GridBoxProps {
  onClickHandler?: (id: string) => void;
  id: string;
  gridItems: GridItem[];
}

const colSpanClass = (colSpan: number) => {
  switch (colSpan) {
    case 1:
      return 'col-span-1';
    case 2:
      return 'col-span-2';
    case 3:
      return 'col-span-3';
    case 4:
      return 'col-span-4';
    case 5:
      return 'col-span-5';
    case 6:
      return 'col-span-6';
  }
  return "";
}

const GridBox: React.FC<GridBoxProps> = ({ onClickHandler, gridItems, id }) => {
  const totalColumns = 12;
  const totalRows = Math.ceil(gridItems.reduce((acc, item) => acc + item.colSpan, 0) / totalColumns);

  let currentColumn = 0;
  let currentRow = 0;

  return (
    <div className="border border-solid border-gray-300 bg-white text-lg mt-[19px] rounded-[15px] h-[169px]"
      onClick={
        (event) => onClickHandler && onClickHandler(id)
      }
    >
      <div className="grid grid-cols-12 h-full">
        {gridItems.map((item, index) => {
          const isLastColumn = (currentColumn + item.colSpan) % totalColumns === 0;
          const isLastRow = currentRow === totalRows - 1;

          const itemElement = (
            <div
              key={index}
              className={`${colSpanClass(item.colSpan)} ${!isLastColumn ? ' border-r' : ''} ${!isLastRow ? ' border-b' : ''}`}
            >
              <GridContent heading={item.heading} content={item.content} />
            </div>
          );

          currentColumn += item.colSpan;
          if (currentColumn >= totalColumns) {
            currentColumn = 0;
            currentRow++;
          }

          return itemElement;
        })}
      </div>
    </div>
  );
};

export default GridBox;
import { createContext, useContext, useEffect, useState } from "react";
import { trackUser } from "../../apis/tracking";
import { setupZenDesk } from "../../apis/zendesk";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateToken, updateProgram } from "../../store/authSlice";
import jwtDecode from 'jwt-decode';
import AdminRoutes from "../routes/AdminRoutes";

type AdminAuthContextType = {
  isAuthenticated: boolean;
  isLoading: boolean;
  email: string | null;
  accessToken: string | null;
  login: () => void;
  logout: () => void;
};

export const AdminAuthContext = createContext<AdminAuthContextType>({
  isAuthenticated: false,
  isLoading: true,
  email: null,
  accessToken: null,
  login: () => { },
  logout: () => { },
});


export const tokenKey = "adminuser";
const metadataKey = "https://flexcharging.com/claims/user_metadata";

interface DecodedToken {
  'https://flexcharging.com/claims/user_metadata': {
    program: string;
  };
};

export const AdminAuthProvider = ({ children }: { children: JSX.Element }) => {
  const [hasTrackedUser, setHasTrackedUser] = useState<boolean | null>(false);
  const auth0Context = useAuth0();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAuthenticated = auth0Context.isAuthenticated ?? false;
  const isLoading = auth0Context.isLoading ?? true;
  const email = auth0Context.user?.email ?? null;
  const name = auth0Context.user?.name ?? null
  let accessToken = null;

  useEffect(() => {
    if (!isAuthenticated || isLoading || !email) {
      return;
    }

    auth0Context.getAccessTokenSilently().then((token) => {
      accessToken = token;

      // get the user's program from the token metadata
      const idToken = jwtDecode(token) as DecodedToken
      const userProgram = idToken && idToken[metadataKey]?.program;

      // write the token to redux so it can be used in the api module
      dispatch(updateToken(accessToken));
      dispatch(updateProgram(userProgram));
    });

    setupZenDesk(email, name);

  }, [isAuthenticated, isLoading, email, name]);

  const login = () => {
    auth0Context.loginWithRedirect();

    if (email) {
      trackUser(email);
    }
    setHasTrackedUser(true);
  };

  const logout = async () => {
    auth0Context.logout({ logoutParams: { returnTo: window.location.origin + AdminRoutes.LogIn } });
    setHasTrackedUser(false);
    navigate(AdminRoutes.LogIn);
  };

  // get the access token

  const value: AdminAuthContextType = { isAuthenticated, isLoading, email, accessToken, login, logout };



  // this handles the case where the user is already signed in
  if (!hasTrackedUser && email !== null) {
    trackUser(email);
    setHasTrackedUser(true);
  }

  return <AdminAuthContext.Provider value={value}>{children}</AdminAuthContext.Provider>;
};

export const useAdminAuth = () => {
  return useContext(AdminAuthContext);
};


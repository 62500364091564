import { Navigate } from "react-router-dom";
import { useAdminAuth } from "../hooks/useAdminAuth";
import AdminRoutes from "./AdminRoutes";

// just a page that redirect depending on authenication status
const AdminRoot = () => {
  const { isAuthenticated, isLoading } = useAdminAuth();
  if (isLoading) return null;

  if (isAuthenticated) {
    return <Navigate to={AdminRoutes.Dashboard} replace />;
  } else {
    return <Navigate to={AdminRoutes.LogIn} replace />;
  }
};

export default AdminRoot;
import { useCookies } from 'react-cookie';

const BRAND_COOKIE = 'brand';
const DOMAIN = '.flexcharging.com';

// this hook provides the user token in a root domain cookie so we can jump domains
export const useBrandCookie = () => {
  const [cookies, setCookie, removeCookie] = useCookies([BRAND_COOKIE]);

  const brandCookie = cookies[BRAND_COOKIE];

  const setBrandCookie = (token: string | null) => {
    setCookie(BRAND_COOKIE, token, {
      domain: DOMAIN,
      secure: true,
      sameSite: 'lax',
      path: '/',
      expires: new Date(Date.now() + 60 * 60 * 1000) // 1 hour from now
    });

  };

  const removeBrandCookie = () => {
    removeCookie(BRAND_COOKIE, { domain: DOMAIN });
  };

  return [brandCookie, setBrandCookie, removeBrandCookie];
}

import { useCallback } from "react";

const useWindowLocationHref = () => {
  const setUrl = useCallback((url: string) => {
    window.location.href = url;
  }, []);

  return [window.location.href, setUrl] as const;
};

export default useWindowLocationHref;
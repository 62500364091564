import useLocalStorageState from "use-local-storage-state";
import { getToday } from "../../helpers/getDates";
import { useToken } from "../../store/authSlice";
import { useGetDemandResponseEventQuery, useGetDemandResponseEventsQuery } from "../store/apiModule";
import { BrandConfigKey, getBrandConfig } from "../../brand-config";
import AdminRoutes from "./AdminRoutes";
import { Link } from "react-router-dom";
import AdminDemandResponseEvent from "../components/AdminDemandResponseEvent";
import { useMemo, useState } from "react";

const AdminDemandResponse = () => {
  const accessToken = useToken();

  const [savedDefaultProgram] = useLocalStorageState<string>("defaultProgram", { defaultValue: undefined });
  const brandConfig = savedDefaultProgram ? getBrandConfig(savedDefaultProgram.toLowerCase() as BrandConfigKey) : undefined;
  const [showPastEvents, setShowPastEvents] = useState(false);
  const [detailedEventId, setDetailedEventId] = useState<string | null>(null);

  const {
    data: getDemandResponseEventsData,
    isFetching: isDemandResponseEventsFetching,
    isError: isDemandResponseEventsError
  } = useGetDemandResponseEventsQuery({
    programCode: null,
    utilityName: brandConfig?.utilityName || "",
    fromDate: showPastEvents ? null : getToday() || null,
    toDate: showPastEvents ? getToday() || null : null,
  },
    {
      skip: !accessToken || !brandConfig
    });

  const {
    data: getDemandResponseEventDetailsData,
    isFetching: isDemandResponseEventDetailsFetching,
    isError: isDemandResponseEventDetailsError
  } = useGetDemandResponseEventQuery({
    eventId: detailedEventId || "",
    showParticipants: true
  },
    {
      skip: !detailedEventId
    });

  const sortedEvents = useMemo(() => {
    if (getDemandResponseEventsData) {
      const reverse = showPastEvents ? -1 : 1;
      return getDemandResponseEventsData.slice().sort((a, b) => {
        return (new Date(a.startTime).getTime() > new Date(b.startTime).getTime()) ? reverse : -1 * reverse;
      });
    }
    return [];
  }, [getDemandResponseEventsData, showPastEvents]);

  if (!brandConfig?.utilityName) {
    return <div>There was a data error. Return to the <a href={AdminRoutes.Dashboard}>Main Dashboard</a> and try again</div>
  }

  const onClickEventHandler = (id: string) => {
    setDetailedEventId(id);
  }

  return (
    <div>
      <div className="max-w-[1300px] m-auto">
        <div className="text-action text-lg font-sans-bold pt-[10px]"><Link to={AdminRoutes.Dashboard}>{"< Back to Dashboard"}</Link></div>

        <div className="font-sans-bold text-xl py-[20px]">Demand Response Events</div>

        <div className="flex flex-col items-center justify-center px-[40px] lg:px-[50px] pt-[30px] pb-[15px] bg-adminbg">
          <>
            <div className="text-action pb-[10px]">
              <a href="#" onClick={() => setShowPastEvents(false)} className={showPastEvents ? "font-sans" : "font-sans-bold"}>Current Events</a> <span className="text-black">|</span> <a href="#" onClick={() => setShowPastEvents(true)} className={!showPastEvents ? "font-sans" : "font-sans-bold"}>Past Events</a>
            </div>
            {isDemandResponseEventsFetching && <div>Loading...</div>}
            {isDemandResponseEventsError && <div>Data Error</div>}
            {sortedEvents &&
              <div>
                {sortedEvents.map((event) => {
                  return <div key={event.eventId}><AdminDemandResponseEvent event={event} onClickHandler={onClickEventHandler} /></div>
                })}
              </div>
            }
          </>
        </div>
      </div>
    </div>);
};

export default AdminDemandResponse;


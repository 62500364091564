import { useCookies } from 'react-cookie';

const AUTH_TOKEN_COOKIE = 'impersonation';
const DOMAIN = '.flexcharging.com';

export const useImpersonationToken = () => {
  const [cookies, setCookie, removeCookie] = useCookies([AUTH_TOKEN_COOKIE]);

  const impersonationToken = cookies[AUTH_TOKEN_COOKIE];

  const setImpersonationToken = (token: string | null) => {
    setCookie(AUTH_TOKEN_COOKIE, token, {
      domain: DOMAIN,
      secure: true,
      sameSite: 'lax',
      path: '/',
      expires: new Date(Date.now() + 60 * 60 * 1000) // 1 hour from now
    });
  };

  const removeImpersonationToken = () => {
    removeCookie(AUTH_TOKEN_COOKIE, { domain: DOMAIN });
  };

  return [impersonationToken, setImpersonationToken, removeImpersonationToken];
}

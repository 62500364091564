import { useCookies } from 'react-cookie';

const USER_TOKEN_COOKIE = 'user';
const DOMAIN = '.flexcharging.com';

// this hook provides the user token in a root domain cookie so we can jump domains
export const useUserCookie = () => {
  const [cookies, setCookie, removeCookie] = useCookies([USER_TOKEN_COOKIE]);

  const userCookie = cookies[USER_TOKEN_COOKIE];

  const setUserCookie = (token: string | null) => {
    setCookie(USER_TOKEN_COOKIE, token, {
      domain: DOMAIN,
      secure: true,
      sameSite: 'lax',
      path: '/',
      expires: new Date(Date.now() + 60 * 60 * 1000) // 1 hour from now
    });
  };

  const removeUserCookie = () => {
    removeCookie(USER_TOKEN_COOKIE, { domain: DOMAIN });
  };

  return [userCookie, setUserCookie, removeUserCookie];
}

import { QueryString } from "../../constants/queryString";
import getEnv from "../../env";
import useBrandConfig from "../useBrandConfig";
import { useBrandCookie } from "../useBrandCookie";
import { useUserCookie } from "../useUserCookie";

// addvehicle happens on a different domain in prod and dev https and this hook returns the correct url or path for the current environment
// if redirect is true, its going to a different domain cannot be done with react router
export const useAddVehicleUrl = (user: string | null, duringSignUp: boolean, reconnectMake: string | null = null) => {
  const env = getEnv().ENVIRONMENT;
  const config = useBrandConfig();
  const [, setUserCookie] = useUserCookie();
  const [, setUserBrand] = useBrandCookie();

  if (!user) return null;

  if ((env === "production" || (env === "development" && window.location.protocol === "https:"))
    && window.location.hostname !== "evision.flexcharging.com") {
    // store user and brand in a shared cookie so we can use it on the other domain
    setUserCookie(user);
    setUserBrand(config.programName);

    // production add vehicle runs on a generic domain to allows us to use generic redirects for auth flows
    var queryString = "";
    if (duringSignUp) {
      queryString += `?${QueryString.SignUp}`;
    }
    if (reconnectMake) {
      queryString += `${queryString ? "&" : "?"}${QueryString.Reconnect}=${reconnectMake}`;
    }
    return {
      redirect: true,
      url: `https://evision.flexcharging.com/addvehicle${queryString}`
    };
  }
  else {
    return { redirect: false, url: `/addvehicle${duringSignUp ? ("?" + QueryString.SignUp) : ""}` };
  }
}
